import validate from '../../../../../../../util/helpers/validate'
import {
  baseState,
  initialGithubProtocolState,
  initialHttpProtocolState,
} from './initialStates'

const authMapper = {
  BASIC_AUTH: 'basic',
  TOKEN: 'token',
}
const AUTH_VALUE_MAPPER = {
  token: 'BEARER_TOKEN',
  basic: 'BASIC_AUTH',
}

export const generateStateFromWebHook = (currentWebHook) => {
  const newState = JSON.parse(JSON.stringify(baseState))
  if (!currentWebHook) {
    return newState
  }
  newState.protocol.value = currentWebHook.protocol.type.toLowerCase()
  newState.protocol.touched = true
  newState.protocol.valid = validate(
    newState.protocol.value,
    newState.protocol.validationRules,
  )
  newState.producer.value = currentWebHook.producerSlug
  newState.producer.touched = true
  newState.producer.valid = validate(
    newState.producer.value,
    newState.producer.validationRules,
  )
  newState.events.value = currentWebHook.eventSlugs
  newState.events.touched = true
  newState.events.valid = validate(
    newState.events.value,
    newState.events.validationRules,
  )
  newState.tenants.value = currentWebHook.tenants
  newState.tenants.touched = true
  newState.tenants.valid = validate(
    newState.tenants.value,
    newState.tenants.validationRules,
  )
  newState.providers.value = currentWebHook.providers
  newState.providers.touched = true
  newState.providers.valid = validate(
    newState.providers.value,
    newState.providers.validationRules,
  )
  return newState
}

export const generateInitialGithubProtocolState = (currentWebHook) => {
  const newState = JSON.parse(JSON.stringify(initialGithubProtocolState))
  if (!currentWebHook || currentWebHook.protocol.type !== 'GITHUB') {
    return newState
  }
  newState.gitReference.value = currentWebHook.protocol.properties.ref
  newState.gitReference.touched = true
  newState.gitReference.valid = validate(
    newState.gitReference.value,
    newState.gitReference.validationRules,
  )

  newState.organization.value = currentWebHook.protocol.properties.repoOwner
  newState.organization.touched = true
  newState.organization.valid = validate(
    newState.organization.value,
    newState.organization.validationRules,
  )

  newState.repository.value = currentWebHook.protocol.properties.repoName
  newState.repository.touched = true
  newState.repository.valid = validate(
    newState.repository.value,
    newState.repository.validationRules,
  )

  newState.workflowFileName.value =
    currentWebHook.protocol.properties.workflowFilePath
  newState.workflowFileName.touched = true
  newState.workflowFileName.valid = validate(
    newState.workflowFileName.value,
    newState.workflowFileName.validationRules,
  )

  newState.githubToken.value =
    currentWebHook.protocol.properties.accessToken ?? ''
  newState.githubToken.touched = true
  newState.githubToken.valid = validate(
    newState.githubToken.value,
    newState.githubToken.validationRules,
  )

  return newState
}

export const generateInitialHttpProtocolState = (currentWebHook) => {
  const newState = JSON.parse(JSON.stringify(initialHttpProtocolState))
  if (!currentWebHook || currentWebHook.protocol.type !== 'HTTP') {
    return newState
  }

  if (currentWebHook.protocol.properties.authenticationProfile) {
    newState.auth.value =
      authMapper[
        currentWebHook.protocol.properties.authenticationProfile.authenticationMethod
      ]

    newState.auth.touched = true
    newState.auth.valid = validate(
      newState.auth.value,
      newState.auth.validationRules,
    )
  }
  if (
    currentWebHook.protocol.properties.authenticationProfile
      ?.authenticationCredentials?.username
  ) {
    newState.username.value =
      currentWebHook.protocol.properties.authenticationProfile.authenticationCredentials.username
    newState.username.touched = true
    newState.username.valid = validate(
      newState.username.value,
      newState.username.validationRules,
      newState,
    )
  }

  newState.url.value = currentWebHook.protocol.properties.url
  newState.url.touched = true
  newState.url.valid = validate(
    newState.url.value,
    newState.url.validationRules,
  )

  newState.timeout.value = currentWebHook.protocol.properties.timeout
  newState.timeout.touched = true
  newState.timeout.valid = validate(
    newState.timeout.value,
    newState.timeout.validationRules,
  )
  if (currentWebHook.protocol.properties?.customHeaders?.length) {
    newState.headers.value =
      currentWebHook.protocol.properties.customHeaders.map(
        ({ headerName, headerValue }) => ({
          name: headerName,
          value: headerValue,
        }),
      )
    newState.headers.touched = true
    newState.headers.valid = validate(
      newState.headers.value,
      newState.headers.validationRules,
    )
  }
  return newState
}

export const generateGithubProperties = (githubState) => ({
  ref: githubState.gitReference.value,
  accessToken: githubState.githubToken.value,
  repoOwner: githubState.organization.value,
  repoName: githubState.repository.value,
  workflowFilePath: githubState.workflowFileName.value,
})

export const generateHttpProperties = (httpState) => {
  const propsObj = {
    timeout: httpState.timeout.value,
    url: httpState.url.value,
  }
  if (httpState.headers.value.length) {
    propsObj.customHeaders = httpState.headers.value.map(({ name, value }) => ({
      headerName: name,
      headerValue: value,
    }))
  }

  let authData = null
  switch (httpState.auth.value) {
    case 'token':
      authData = {
        token: httpState.token.value,
      }
      break
    case 'basic':
      authData = {
        username: httpState.username.value,
        password: httpState.password.value,
      }
      break
  }

  if (authData) {
    propsObj.authenticationProfile = {
      authenticationMethod: AUTH_VALUE_MAPPER[httpState.auth.value],
      authenticationCredentials: authData,
    }
  }

  return propsObj
}

export const generateWebHookPayload = (state, protocolProps) => ({
  eventSlugs: Array.isArray(state.events.value)
    ? state.events.value
    : [state.events.value],
  producerSlug: state.producer.value,
  protocol: {
    properties: protocolProps,
    type: state.protocol.value.toUpperCase(),
  },
  providerSlugs: Array.isArray(state.providers.value)
    ? state.providers.value.map(({ value }) => value)
    : [state.providers.value],
  scopes: Array.isArray(state.tenants.value)
    ? state.tenants.value.map(({ value }) => value)
    : [state.tenants.value],
  systemWebhook: true,
})

export const touchState = (state) => {
  const newState = JSON.parse(JSON.stringify(state))
  for (const control in newState) {
    newState[control].touched = true
    newState[control].valid = validate(
      newState[control].value,
      newState[control].validationRules,
      newState,
    )
  }
  return newState
}

export const checkStateValidity = (state) => {
  let isDirty = false
  Object.keys(state).forEach((control) => {
    if (!state[control].valid) {
      isDirty = true
    }
  })
  return isDirty
}

export const generateCurrentWebHook = (
  currentWebHook,
  providers,
  allTenants,
) => {
  if (!(currentWebHook && providers && allTenants)) {
    return currentWebHook
  }
  const providersObj = generateObjectFromProviders(providers)
  return {
    ...currentWebHook,
    tenants: Array.isArray(currentWebHook.scopes)
      ? currentWebHook.scopes.map((acronym) => ({
          label: allTenants[acronym].name,
          value: acronym,
        }))
      : currentWebHook.scopes,
    providers: Array.isArray(currentWebHook.providerSlugs)
      ? currentWebHook.providerSlugs.map((provider) => providersObj[provider])
      : currentWebHook.providerSlugs,
  }
}

export const generateObjectFromProviders = (providers) => {
  const obj = {}
  providers.forEach(
    ({ slug, name }) => (obj[slug] = { label: name, value: slug }),
  )
  return obj
}
