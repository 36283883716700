/**
 * Asserts that the condition is true. If the condition is false, it throws an error
 * with the provided message or logs a warning in soft mode.
 *
 * @example
 *
 * assert(Array.isArray([])); // Does nothing
 * assert(typeof foo === 'string', 'This is an error message'); // Throws an error
 * assert(x > 0, 'x must be positive', { value: x }); // Throws with context
 *
 * @param condition - The condition to assert, or an array of conditions.
 * @param [message] - (Optional) The error message to throw. Default is 'Assertion failed'.
 * @param options - Assertion options.
 * @param [options.context] - (Optional) Additional debugging information (e.g., variable values).
 * @param [options.ErrorType] - (Optional) The error class to throw (default: `Error`).
 * @param [options.soft] - (Optional) If `true`, logs a warning instead of throwing an error.
 */
export function assert(
  condition,
  message,
  { context, ErrorType = Error, soft = false } = {},
) {
  // Handle multiple conditions
  const failed = Array.isArray(condition)
    ? condition.some((c) => !c)
    : !condition

  if (!failed) {
    return
  }

  // Construct error message
  const errorMessage = message ?? 'Assertion failed'
  const errorDetails = context
    ? `\nContext: ${JSON.stringify(context, null, 2)}`
    : ''

  if (soft) {
    console.warn(`Warning: ${errorMessage}${errorDetails}`)
    return
  }

  throw new ErrorType(`${errorMessage}${errorDetails}`)
}
