import React from 'react'
import PropTypes from 'prop-types'
import { Button, Spinner } from '../../../../../../common'

export const ExportButtonComponent = ({ title, loading, onClickExport }) => (
  <Button
    variant="secondary"
    size="large"
    disabled={loading}
    onClick={onClickExport}
    withIcon={!loading ? 'bds/disc/16' : ''}
  >
    {loading ? (
      <Spinner className="wb-margin-start-xxs" withText="Exporting ..." />
    ) : (
      title
    )}
  </Button>
)

ExportButtonComponent.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  onClickExport: PropTypes.func,
}
