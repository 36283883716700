import {
  CHANGE_API_ACCESS,
  CHANGE_CREATING,
  CHANGE_DELETING,
  CHANGE_EDITING,
  CHANGE_FORM_DATA,
  CHANGE_SAVING,
  CHANGE_SERVER_ERRORS,
  DELETE_TENANT,
  FILTER_TENANTS,
  RECEIVE_SCHEMA,
  RECEIVE_TENANTS,
  REQUEST_TENANTS,
  SELECT_TENANT,
  SET_ARTS,
  SET_CURRENT_TENANT_MEMBERS,
  SET_MEMBER_EXPIRING_DATE_RANGE,
  SET_TENANT_COMPLIANCE_INFO,
  SET_TENANT_ROLES,
  UPDATE_TENANT,
} from '../../actionsTypes'
import {
  generateFilteredTenants,
  generateNebulaMembers,
  generateSampleObjFromJSONSchema,
  mapTenantMembersWithPermissions,
  modifyComplianceDataResponceBody,
  normalizeServerErrors,
} from '../../../util'
import {
  batchAddTenantMembers,
  batchDeleteTenantMembers,
  changeTenantProvider,
  createTenant,
  deleteTenantMember,
  fetchAllTenants,
  batchAddLabelsMembers,
  fetchArts,
  fetchTenant,
  fetchTenantComplianceData,
  fetchTenantMemberPermissions,
  fetchTenantRoles,
  removeTenant,
  saveTenantComplianceData,
  setTenantMembersExpiryDate,
  updateTenantData,
  updateTenantMember,
} from '../../../http'
import { postNotification } from '../'

export const getArts = () => async (dispatch) => {
  try {
    const arts = await fetchArts()
    dispatch(setArts(arts))
  } catch (err) {
    dispatch(
      postNotification({
        message: err.message,
        mode: 'error',
      }),
    )
  }
}

export const getTenantRoles = () => async (dispatch) => {
  try {
    const roles = await fetchTenantRoles()
    dispatch(setTenantRoles(roles))
  } catch (err) {
    dispatch(
      postNotification({
        message: err.message,
        mode: 'error',
      }),
    )
  }
}

export const changeProvider = (provider) => async (dispatch, getState) => {
  try {
    const acronym = getState().tenants.selectedTenant
    await changeTenantProvider({ acronym, provider })
    const updatedTenant = await fetchTenant(acronym)
    dispatch(updateTenant(updatedTenant))
    dispatch(
      postNotification({
        message: 'Tenant provider was successfully updated!',
      }),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: err.message,
        mode: 'error',
      }),
    )
  }
}

export const getTenantMembersWithPermissions =
  (acronym) => async (dispatch, getState) => {
    try {
      acronym ||= getState().tenants.selectedTenant
      if (acronym) {
        const memberPermissions = await fetchTenantMemberPermissions(acronym)

        const tenant = await fetchTenant(acronym)
        const mappedMembersWithPermissions = mapTenantMembersWithPermissions(
          tenant[acronym].members,
          memberPermissions,
        )
        dispatch(setSelectedTenantMembers(mappedMembersWithPermissions))
      }
    } catch (err) {
      dispatch(
        postNotification({
          message: err.message,
          mode: 'error',
        }),
      )
    }
  }

const fetchSchema = () => async (dispatch) => {
  let response, generatedSample, hardcodedData, errors

  try {
    response = await window.fetch('assets/mocks/schema.json')
    response = await response.json()
    response = normalizeServerErrors(response)
    hardcodedData = {
      isComplianceDataFresh: true,
    }
    generatedSample = generateSampleObjFromJSONSchema(response, hardcodedData)
    errors = response.hasErrors ? response : false
  } catch (e) {
    errors = {
      message: e.message || e.error || 'Unknown',
      hasErrors: true,
    }
  }
  if (
    !errors &&
    typeof response === 'object' &&
    Object.keys(response).length > 0
  ) {
    return dispatch(receiveSchema(response, generatedSample))
  }
  dispatch(changeServerErrors(errors))
  return dispatch(changeApiAccess(false))
}

export const fetchSchemaIfNeeded = () => (dispatch, getState) => {
  if (!getState().tenants.schema) {
    dispatch(fetchSchema())
  }
}

export const fetchTenantsIfNeeded = () => (dispatch, getState) => {
  if (!Object.keys(getState().tenants.allTenants).length) {
    dispatch(fetchTenants())
  }
}

export const filterTenants = (filterTerm) => (dispatch, getState) => {
  const _allTenants = getState().tenants.allTenants
  const _roles = getState().tenants.roles
  if (Object.keys(_allTenants).length) {
    dispatch(filteredTenants(filterTerm || '', _allTenants, _roles))
  }
}

export const startSaving = (acronym, data) => {
  const tenantToSave = {
    ...data,
  }
  delete tenantToSave.disabledPlanningIT
  delete tenantToSave.personalData
  delete tenantToSave.continuity
  delete tenantToSave.availability
  delete tenantToSave.integrity
  delete tenantToSave.confidentiality
  delete tenantToSave.icto

  return async (dispatch, getState) => {
    const isCreating = getState().tenants.isCreating
    const isDeleting = getState().tenants.isDeleting
    let response, errors

    dispatch(changeSaving(true))

    try {
      if (isCreating) {
        response = await createTenant(tenantToSave)
      } else if (isDeleting) {
        response = await removeTenant(acronym)
      } else {
        response = await updateTenantData(acronym, tenantToSave)
      }
      response = normalizeServerErrors(response)
      errors = response.hasErrors ? response : false
    } catch (e) {
      errors = {
        message: e.message || e.error || 'Unknown',
        hasErrors: true,
      }
    }

    if (!errors) {
      if (isDeleting) {
        dispatch(deleteTenant(acronym))
        dispatch(selectTenant(''))
      } else {
        dispatch(updateTenant(response))
      }

      dispatch(
        filteredTenants(
          getState().tenants.filterTerm,
          getState().tenants.allTenants,
        ),
      )
      if (isCreating) {
        dispatch(selectTenant(tenantToSave.acronym))
      }
      dispatch(changeSaving(false))
      dispatch(changeServerErrors(null))
      dispatch(changeCreating(false))
      dispatch(changeDeleting(false))
      return dispatch(changeEditing(false))
    }

    if (errors) {
      dispatch(changeFormData(tenantToSave))
    }

    dispatch(changeServerErrors(errors))
    return dispatch(changeSaving(false))
  }
}

const fetchTenants = () => {
  return async (dispatch, getState) => {
    let response, errors

    dispatch(requestTenants())

    try {
      response = await fetchAllTenants()
      response = normalizeServerErrors(response)
      errors = response.hasErrors ? response : false
    } catch (e) {
      errors = {
        message: e.message || e.error || 'Unknown',
        hasErrors: true,
      }
    }

    const objKeys = typeof response === 'object' ? Object.keys(response) : []

    if (!errors && objKeys.length > 0) {
      const dateRange = response[objKeys[0]].memberExpiringDateRange
      dispatch(setMemberExpiringDateRange(dateRange))
      dispatch(receiveTenants(response))
      dispatch(changeServerErrors(null))
      dispatch(changeApiAccess(true))
      return dispatch(filteredTenants('', getState().tenants.allTenants))
    }

    dispatch(changeServerErrors(errors))
    return dispatch(changeApiAccess(false))
  }
}

export const getTenantComplianceData = () => async (dispatch, getState) => {
  try {
    const tenantAcr = getState().tenants.selectedTenant
    const roles = getState().sso.roles
    if (tenantAcr && roles.includes('compliance')) {
      const response = await fetchTenantComplianceData(tenantAcr)
      dispatch(setComplianceInfo(modifyComplianceDataResponceBody(response)))
    }
  } catch (err) {
    dispatch(
      postNotification({
        message: err.message,
        mode: 'error',
      }),
    )
  }
}

export const updateComplianceData = (payload) => async (dispatch, getState) => {
  try {
    const tenantAcr = getState().tenants.selectedTenant
    await saveTenantComplianceData(tenantAcr, payload)
    dispatch(getTenantComplianceData())
    dispatch(
      postNotification({
        message:
          "The Tenant's compliance information was updated successfully!",
      }),
    )
  } catch (err) {
    dispatch(
      postNotification({
        message: `Error occured when updating Tenant's compliance information - ${err.message}`,
        mode: 'error',
      }),
    )
  }
}

export const removeTenantMember =
  ({ tenantAcr, daimlerId }, cb) =>
  async (dispatch) => {
    try {
      await deleteTenantMember({ tenantAcr, daimlerId })
      dispatch(getTenantMembersWithPermissions(tenantAcr))
      cb?.()
      dispatch(
        postNotification({
          message: `The Tenant member (${daimlerId}) was removed successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while removing Tenant Members (${daimlerId}) - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const batchRemoveTenantMembers =
  ({ tenantAcr, daimlerIds }, cb) =>
  async (dispatch) => {
    try {
      await batchDeleteTenantMembers({ tenantAcr, daimlerIds })
      dispatch(getTenantMembersWithPermissions(tenantAcr))
      cb?.()
      dispatch(
        postNotification({
          message: `The Tenant members (${daimlerIds.join(
            ', ',
          )}) were removed successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while removing Tenant Members (${daimlerIds.join(
            ', ',
          )}) - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const bacthUpdateTenantMemberLabels =
  ({ tenantAcr, daimlerIds, labels }, cb) =>
  async (dispatch) => {
    try {
      await batchAddLabelsMembers({ tenantAcr, daimlerIds, labels })
      dispatch(getTenantMembersWithPermissions(tenantAcr))
      cb?.()
      dispatch(
        postNotification({
          message: `The Tenant member (${daimlerIds}) was updated successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while updating Tenant Member (${daimlerIds}) - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const addNewTenantMembers =
  ({ tenantAcr, daimlerIds, roles, labels, tenantUserRoleExpiryDate }, cb) =>
  async (dispatch) => {
    try {
      await batchAddTenantMembers({
        tenantAcr,
        daimlerIds,
        roles,
        labels,
        tenantUserRoleExpiryDate,
      })
      dispatch(getTenantMembersWithPermissions(tenantAcr))
      cb?.()
      dispatch(
        postNotification({
          message: `Tenant members (${daimlerIds.join(
            ', ',
          )}) were added successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while adding Tenant Members (${daimlerIds.join(
            ', ',
          )}) - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const extendTenantMembers =
  ({ tenantAcr, daimlerIds, tenantUserRoleExpiryDate }, cb) =>
  async (dispatch) => {
    try {
      await setTenantMembersExpiryDate({
        tenantAcr,
        daimlerIds,
        tenantUserRoleExpiryDate,
      })
      dispatch(getTenantMembersWithPermissions(tenantAcr))
      cb?.()
      dispatch(
        postNotification({
          message: `Tenant members (${daimlerIds.join(
            ', ',
          )}) were updated successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while updating Tenant Members (${daimlerIds.join(
            ', ',
          )}) - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const updateTenantMemberRoles =
  ({ tenantAcr, daimlerId, roles, labels, tenantUserRoleExpiryDate }, cb) =>
  async (dispatch) => {
    try {
      await updateTenantMember({
        tenantAcr,
        daimlerId,
        roles,
        labels,
        tenantUserRoleExpiryDate,
      })

      dispatch(getTenantMembersWithPermissions(tenantAcr))
      cb?.()
      dispatch(
        postNotification({
          message: `The Tenant member (${daimlerId}) was updated successfully!`,
        }),
      )
    } catch (err) {
      dispatch(
        postNotification({
          message: `Error occured while updating Tenant Member (${daimlerId}) - ${err.message}`,
          mode: 'error',
        }),
      )
    }
  }

export const requestTenants = () => ({
  type: REQUEST_TENANTS,
})

export const changeApiAccess = (apiAccess) => ({
  type: CHANGE_API_ACCESS,
  payload: {
    apiAccess,
  },
})

export const receiveSchema = (schema, sample) => ({
  type: RECEIVE_SCHEMA,
  payload: {
    schema,
    sample,
  },
})

export const setArts = (arts) => ({
  type: SET_ARTS,
  payload: arts,
})

export const receiveTenants = (allTenants) => ({
  type: RECEIVE_TENANTS,
  payload: {
    apiAccess: true,
    allTenants,
    nebulaMembers: generateNebulaMembers(allTenants),
  },
})

export const setSelectedTenantMembers = (tenantMembers) => ({
  type: SET_CURRENT_TENANT_MEMBERS,
  payload: { tenantMembers },
})

export const setMemberExpiringDateRange = (memberExpiringDateRange) => ({
  type: SET_MEMBER_EXPIRING_DATE_RANGE,
  payload: {
    memberExpiringDateRange,
  },
})

export const filteredTenants = (filterTerm, allTenants, roles = []) => ({
  type: FILTER_TENANTS,
  payload: {
    filterTerm,
    filteredTenants: generateFilteredTenants(filterTerm, allTenants, roles),
  },
})

export const selectTenant = (selectedTenant) => ({
  type: SELECT_TENANT,
  payload: {
    selectedTenant,
  },
})

export const deleteTenant = (acronym) => ({
  type: DELETE_TENANT,
  payload: {
    acronym,
  },
})

export const changeEditing = (isEditing) => ({
  type: CHANGE_EDITING,
  payload: {
    isEditing,
  },
})

export const changeCreating = (isCreating) => ({
  type: CHANGE_CREATING,
  payload: {
    isCreating,
  },
})

export const changeDeleting = (isDeleting) => ({
  type: CHANGE_DELETING,
  payload: {
    isDeleting,
  },
})

export const changeSaving = (isSaving) => ({
  type: CHANGE_SAVING,
  payload: {
    isSaving,
  },
})

export const updateTenant = (data) => ({
  type: UPDATE_TENANT,
  payload: {
    updatedTenant: data,
  },
})

export const changeServerErrors = (data) => ({
  type: CHANGE_SERVER_ERRORS,
  payload: {
    serverErrors: data,
  },
})

export const changeFormData = (formData) => ({
  type: CHANGE_FORM_DATA,
  payload: {
    formData,
  },
})

export const setTenantRoles = (roles) => ({
  type: SET_TENANT_ROLES,
  payload: {
    roles,
  },
})

export const setComplianceInfo = (complianceInfo) => ({
  type: SET_TENANT_COMPLIANCE_INFO,
  payload: {
    complianceInfo,
  },
})
