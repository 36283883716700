import {
  SET_WEB_HOOK,
  SET_WEB_HOOKS,
  SET_WEB_HOOKS_PRODUCERS,
  SET_WEB_HOOKS_PROVIDERS,
  SET_WEB_HOOKS_EVENTS,
} from '../../actionsTypes'

const initialState = {
  webHooks: {},
  producers: [],
  providers: [],
  events: [],
  currentWebHook: null,
  loading: false,
}

export const hermesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WEB_HOOK:
      return {
        ...state,
        currentWebHook: action.payload,
      }
    case SET_WEB_HOOKS:
      return {
        ...state,
        webHooks: action.payload,
      }
    case SET_WEB_HOOKS_PRODUCERS:
      return {
        ...state,
        producers: action.payload,
      }
    case SET_WEB_HOOKS_PROVIDERS:
      return {
        ...state,
        providers: action.payload,
      }
    case SET_WEB_HOOKS_EVENTS:
      return {
        ...state,
        events: action.payload,
      }
    default:
      return state
  }
}
