import React from 'react'
import PropTypes from 'prop-types'
import validate from '../../../../../../../util/helpers/validate'
import { Input, Tooltip } from '../../../../../../common'
import { generateInputError } from '../../controls'

export const ControllingForm = ({ provider, controls, onChange }) => {
  const updateInputState = (control, value) =>
    onChange({
      ...controls,
      [control]: {
        ...controls[control],
        value: value,
        touched: true,
        valid: validate(value, controls[control].validationRules),
      },
    })

  return (
    <div style={{ background: '#ccc' }} className="wb-padding-xs">
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
        className="wb-margin-bottom-xs"
      >
        <div>{provider} Controlling Information</div>
        <Tooltip
          message="The cost number and cost centre must be specified, as a contract is signed when services are booked. If you do not have the data for your tenant available, please contact your budget approver (Tenant E4) or the responsible budget controller."
          className="wb-margin-start-xs"
          position="top"
          icon="bds/exclamation-mark/16"
        />
      </div>
      <div className="wb-margin-bottom-xs">
        <Input
          label="* Cost center"
          value={controls.costCenter.value}
          error={generateInputError(controls, 'costCenter')}
          onChange={(e) => updateInputState('costCenter', e.target.value)}
        />
      </div>
      <div className="wb-margin-bottom-xs">
        <Input
          label="* Internal Order Number"
          value={controls.costNumber.value}
          error={generateInputError(controls, 'costNumber')}
          onChange={(e) => updateInputState('costNumber', e.target.value)}
        />
      </div>
      <div className="wb-margin-bottom-xs">
        <Input
          label="PSP number"
          value={controls.pspNumber.value}
          error={generateInputError(controls, 'pspNumber')}
          onChange={(e) => updateInputState('pspNumber', e.target.value)}
        />
      </div>
      <div className="wb-margin-bottom-xs">
        <Input
          label="ISA number"
          value={controls.isaNumber.value}
          error={generateInputError(controls, 'isaNumber')}
          onChange={(e) => updateInputState('isaNumber', e.target.value)}
        />
      </div>
      <div className="wb-margin-bottom-xs">
        <Input
          label="Purchase Order"
          value={controls.purchaseOrder.value}
          error={generateInputError(controls, 'purchaseOrder')}
          onChange={(e) => updateInputState('purchaseOrder', e.target.value)}
        />
      </div>
    </div>
  )
}

ControllingForm.propTypes = {
  provider: PropTypes.string,
  controls: PropTypes.object,
  onChange: PropTypes.func,
}
